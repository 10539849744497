















































import { computed, defineComponent } from "@vue/composition-api"

import useJeopardAiResults from "../GroupTeams/Common/Games/JeopardAI/use/useJeopardAiResults"

import TeamTag from "@/components/GroupTeams/Common/TeamTag.vue"
import JeopardAiTeamResultCard from "@/components/GroupTeams/Common/JeopardAiTeamResultCard.vue"
import JeopardAiChallengeResultCard from "@/components/GroupTeams/Common/JeopardAiChallengeResultCard.vue"

export default defineComponent({
  name: "JeopardAiResultsMobile",
  components: {
    JeopardAiChallengeResultCard,
    JeopardAiTeamResultCard,
    TeamTag
  },
  setup() {
    const { teams: sorted, challenges, state } = useJeopardAiResults()

    const isChallenge = computed(() => challenges.value.length > 0)

    const items = computed(() =>
      isChallenge.value ? challenges.value : sorted.value
    )

    return { isChallenge, challenges, state, items }
  }
})
