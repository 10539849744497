var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-results-mobile" },
    [
      _c(
        "transition",
        {
          attrs: {
            name: "jeopardai-results-mobile__transition",
            mode: "out-in",
          },
        },
        [
          _c(
            "div",
            {
              key: _vm.isChallenge ? "wrapup" : "results",
              staticClass: "jeopardai-results-mobile__content",
            },
            _vm._l(_vm.items, function (team, index) {
              return _c(
                "div",
                {
                  key: team.id + "__card",
                  staticClass: "jeopardai-results-mobile__item",
                },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "jeopardai-results-mobile__tag-transition",
                        mode: "out-in",
                      },
                    },
                    [
                      team.showTag
                        ? _c("TeamTag", {
                            key: team.id + "__tag",
                            staticClass: "jeopardai-results-mobile__tag",
                            class: {
                              "jeopardai-results-mobile__tag--active":
                                team.active,
                            },
                            attrs: {
                              team: team,
                              pinnable: false,
                              score: false,
                              mode: "play",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    _vm.isChallenge
                      ? "JeopardAiChallengeResultCard"
                      : "JeopardAiTeamResultCard",
                    {
                      tag: "component",
                      staticClass: "jeopardai-results-mobile__card",
                      attrs: {
                        team: team,
                        position: index + 1,
                        flipped: team.flipped,
                        current: team.active,
                      },
                    }
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }